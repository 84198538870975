<template>
  <div class="modal-signature-subview">
      <div class="tabs">
        <div class="tab" :class="[selectedTab == 'adopt' ? 'selected' : '']" @click.prevent="selectTab('adopt')">Adopt</div>
        <div class="tab" :class="[selectedTab == 'draw' ? 'selected' : '']" @click.prevent="selectTab('draw')">Draw</div>
      </div>

      <div class="container">
        <div class="adopt" v-show="selectedTab == 'adopt'">
          <div style="font-size: 10px; user-select: none;">Type in your full name and click "Adopt" when you're done<span style="color: red;"> *</span></div>
          <input type="text" class="adopt-input" ref="adoptinput" v-model="adoptData" autofocus>
          <div class="adopt-signature-preview">{{ adoptData }}</div>
        </div>

        <div class="draw" v-show="selectedTab == 'draw'">
          <div style="font-size: 10px; user-select: none;">Use your cursor (or your finger) to write your signature and click "Accept" when you're done<span style="color: red;"> *</span></div>



          <canvas class="draw-canvas" id="drawcanvas" ref="drawcanvas" :height="canvasHeight" :width="canvasWidth"
          @mousedown="onMouseDown" 
          @mousemove="onMouseMove" 
          @mouseup="sign = false" 

          @touchstart.prevent="onTouchStart"
          @touchmove.prevent="onTouchMove"
          @touchend.prevent="sign = false"
          ></canvas>
          




        </div>
      </div>

      <div class="disclaimer" v-show="selectedTab == 'adopt'">
        By selecting Adopt, I agree that the signature and initials will be the electronic representation of my signature and initials for all purposes when I (or my agent) use them on documents, including legally binding contracts - just the same as a pen-and-paper signature or initial.
      </div>

      <div class="button-container">
            <div class="adopt-button-container" v-show="selectedTab == 'adopt'">
              <button class="accept" :class="[adoptDataValid ? '' : 'disabled']" @click="confirmSignature">Adopt</button>
              <button class="cancel" @click="$emit('cancelled')">Cancel</button>
            </div>

            <div class="draw-button-container" v-show="selectedTab == 'draw'">
              <button class="accept" :class="[isCanvasBlank ? 'disabled' : '']" @click="confirmSignature">Accept</button>
              <button class="clear" @click.prevent="clearCanvas">Clear</button>
              <button class="cancel" @click="$emit('cancelled')">Cancel</button>
            </div>
      </div>     
  </div>
</template>

<script>
export default {
  emits: ['cancelled', 'accepted'],
  data() {
    return { 
      selectedTab: "adopt",
      drawData: "",
      adoptData: "",
      canvasHeight: 200,
      canvasWidth: 420,
      ctx: null,
      sign: false,
      prevX : null,
      prevY : null,
      isCanvasBlank: true
    }
  },
  mounted() {
    this.ctx  = this.canvas.getContext('2d', { willReadFrequently: true });
    this.ctx.strokeStyle = 'black';
    this.ctx.lineWidth = 2;

    window.addEventListener('resize', this.handleCanvasResize);
    this.handleCanvasResize();
  },
  computed: {
    signatureModalVisible() { 
      return this.$store.getters.signatureModalVisible;
    },
    canvas() {
      return this.$refs.drawcanvas;
    },
    adoptDataValid() {
      return this.adoptData.trim().length > 0;
    }
  },
  methods: {
    confirmSignature() {
      if(this.selectedTab == "adopt") {
        if(!this.adoptData) {
          return;
        }
      }
      else {
        if(this.isCanvasBlank) {
          return;
        }
      }

      var data = this.selectedTab == "adopt" ? this.adoptData.trim() : this.canvas.toDataURL('image/png');

      this.$emit("accepted", { completed: true, type: this.selectedTab, data: data });
    },
    selectTab(tab) {
      this.selectedTab = tab;

      if(tab == "draw")
        this.handleCanvasResize();
    },
    onMouseDown($event){
      var mousePosition = this.getMousePosition($event);
      this.sign = true;
      this.prevX = mousePosition.x;
      this.prevY = mousePosition.y;
    },
    onTouchStart($event) {
      var touchPosition = this.getTouchPosition($event);
      var touch = $event.touches[0];
      var mouseEvent = new MouseEvent("mousedown", {
        clientX: touch.clientX,
        clientY: touch.clientY
      });
      this.$refs.drawcanvas.dispatchEvent(mouseEvent);
    },
    onMouseMove($event) {
      if(this.sign) {
        this.isCanvasBlank = false;
        var mousePosition = this.getMousePosition($event);

        const currX = mousePosition.x;
        const currY = mousePosition.y;

        this.draw(this.prevX, this.prevY, currX, currY);

        this.prevX = currX;
        this.prevY = currY;
      }
    },
    onTouchMove($event) {
      this.isCanvasBlank = false;
      var touch = $event.touches[0];
      var mouseEvent = new MouseEvent("mousemove", {
        clientX: touch.clientX,
        clientY: touch.clientY
      });
      this.$refs.drawcanvas.dispatchEvent(mouseEvent);
    },
    draw(depX, depY, destX, destY){
      this.ctx.beginPath()
      this.ctx.moveTo(depX, depY)
      this.ctx.lineTo(destX, destY)
      this.ctx.closePath()
      this.ctx.stroke()

      this.drawData = this.canvas.toDataURL("image/png");
    },
    getMousePosition(mouseEvent) {
      var rect = this.canvas.getBoundingClientRect();
      return {
        x: mouseEvent.clientX - rect.left,
        y: mouseEvent.clientY - rect.top
      };
    },
    getTouchPosition(touchEvent) {
      var rect = this.canvas.getBoundingClientRect();
      return {
        x: touchEvent.touches[0].clientX - rect.left,
        y: touchEvent.touches[0].clientY - rect.top
      };
    },
    clearCanvas() {
      this.isCanvasBlank = true;
      this.drawData = "";
      this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
    },
    handleCanvasResize() {
      this.$nextTick(() => {
        var rect = this.canvas.getBoundingClientRect();
        this.canvasHeight = rect.height;
        this.canvasWidth = rect.width;

        if(this.canvas.height > 0 && this.canvas.width > 0) {
          if(this.canvasBlank()) {
            this.isCanvasBlank = true;
          }

          let draw = ()=> this.ctx.drawImage(this.img, 0, 0, this.canvas.width, this.canvas.height);
          this.img = new Image();
          this.img.onload = draw;
          this.img.src = this.drawData;
        }
      });
    },
    canvasBlank() {
      const pixelBuffer = new Uint32Array(
        this.ctx.getImageData(0, 0, this.canvas.width, this.canvas.height).data.buffer
      );

      return !pixelBuffer.some(color => color !== 0);
    }
  },
  watch: {
    signatureModalVisible(newValue, oldValue) {
      if (newValue == true) {
        this.$nextTick(() => {
          this.$refs.adoptinput.focus();
        });
      }
    }
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }
}
</script>

<style lang="scss" scoped>
.modal-signature-subview {
  width: 75vw;
  max-width: 500px;
}

.tabs {
  display: flex;
  justify-content: center;
  gap: 30px;
  font-size: 30px;
}

.tab {
  //transition: border 0.1s ease;
  cursor: pointer;
  user-select: none;
}

.selected {
  border-bottom: 3px #F68C23 solid;
}

.container {
  margin-top: 20px;
}

.adopt-signature-preview {
  width: 100%;
  font-family: 'Cedarville Cursive', cursive;
  display: flex;
  justify-content: center;
  align-items: center;
  height: max-content;
  font-size: 24px;
  min-height: 125px;
  box-sizing: border-box;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.adopt {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}

.adopt-input {
  width: 80%;
  padding-left: 10px;
  box-sizing: border-box;
  height: 40px;
  border:none;
  border: 2px dotted rgba(0,0,0,0.2);
  // outline: none;
  text-align: center;
  border-radius: 15px;
}

.draw {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  box-sizing: border-box;
  padding-bottom: 50px;
}

.draw-canvas {
  width: 100%;
  height: 200px;
  border-bottom: 2px black solid;
  cursor: crosshair;
  // touch-action: none;
}

.disclaimer {
  font-size: 12px;
}

.adopt-button-container {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.draw-button-container {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.button-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}

button {
  background-color: #4CAF50; /* Green */
  border: none;
  color: white;
  padding: 12px;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  border-radius: 3px;
}

.accept {
  background-color: #4CAF50;
}

.clear {
  background-color: #ffa600;
}

.cancel {
  background-color: #f44336;
}

.disabled {
  background-color: rgba(0,0,0,0.2);
  cursor: not-allowed;
}

@media (min-width: $media-min-width) {
  .adopt-signature-preview {
    font-size: 42px;
  }
}
</style>