<template>
<transition name="fade" mode="out-in">
  <div class="modal-mask" @click="close()">
      <div class="modal-container" @click.stop>
          <div class="title-container" v-if="title">{{ title }}</div>
          <div class="close-button" @click="close()"><svg style="fill: white;" viewBox="0 0 20 20">
<path d="M10.185,1.417c-4.741,0-8.583,3.842-8.583,8.583c0,4.74,3.842,8.582,8.583,8.582S18.768,14.74,18.768,10C18.768,5.259,14.926,1.417,10.185,1.417 M10.185,17.68c-4.235,0-7.679-3.445-7.679-7.68c0-4.235,3.444-7.679,7.679-7.679S17.864,5.765,17.864,10C17.864,14.234,14.42,17.68,10.185,17.68 M10.824,10l2.842-2.844c0.178-0.176,0.178-0.46,0-0.637c-0.177-0.178-0.461-0.178-0.637,0l-2.844,2.841L7.341,6.52c-0.176-0.178-0.46-0.178-0.637,0c-0.178,0.176-0.178,0.461,0,0.637L9.546,10l-2.841,2.844c-0.178,0.176-0.178,0.461,0,0.637c0.178,0.178,0.459,0.178,0.637,0l2.844-2.841l2.844,2.841c0.178,0.178,0.459,0.178,0.637,0c0.178-0.176,0.178-0.461,0-0.637L10.824,10z"></path>
</svg></div>
            <div class="modal-content-container">
              <slot/>
            </div>
      </div>
  </div>
</transition>
</template>

<script>
export default {
    props: ["title"],
    methods: {
        close: function() {
            this.$emit('close')
        }
    },
    emits: ['close']
}
</script>

<style lang="scss" scoped>
.modal-mask {
  position: fixed;
  // z-index: 99998;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, .3);
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(2px); 
}

.modal-container {
    max-width: 75vw;
    padding: 20px;
    background: white;
    border-radius: 10px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .43);
    position: relative;
    // z-index: 99999;
}

.modal-content-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
}

.title-container {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 24px;
    margin-bottom: 10px;
}

.close-button {
    width: 40px;
    height: 40px;
    position: absolute;
    top: -15px;
    right: -15px;
    box-shadow: 0 2px 3px rgba(0, 0, 0, .33);
    background-color: #d9534f;
    color: white;
    border-radius: 50%;
    cursor: pointer;
}

.close-button div {
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.2s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  transform: scale(1.1);
}
</style>