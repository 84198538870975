<template>
  <div class="hiw-card-root">
      <div class="header-container">
          <div :style="{backgroundColor: colorWithOpacity}" class="number-tag"><span :style="{color: color}">{{ number }}</span></div>
      </div>
      <div class="content-container">
        <div class="body" v-html="content.body"></div>

        <div class="image-container">
            <img v-if="imageSrc" :src="imageSrc" :alt="`Step ${number} image`" :style="imageDimensions"/>
        </div>

        <div class="footer" :style="{...footerStyle}" v-html="content.footer"></div>
      </div>
  </div>
</template>

<script>
export default {
    name: "HowItWorksSectionCard",
    props: {
        number: {
            type: String,
            default: '1',
            required: true
        },
        content: {
            type: Object,
            required: true
        },
        color: {
            type: String,
            default: '#ffffff',
            required: true
        },
        imageSrc: {
            type: String,
            required: false
        },
        imageDimensions: {
            type: Object,
            required: false
        },
        footerStyle: {
            type: Object,
            required: false
        }
    },
    computed: {
        colorWithOpacity() {
            const hex = this.color.replace('#', '');
            const r = parseInt(hex.substring(0, 2), 16);
            const g = parseInt(hex.substring(2, 4), 16);
            const b = parseInt(hex.substring(4, 6), 16);

            return `rgba(${r}, ${g}, ${b}, 0.2)`;
        }
    },
    components: {

    }
}
</script>

<style lang="scss" scoped>
.hiw-card-root {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 300px;
    height: 100%;

    background: white;
    border-radius: 15px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    padding: 15px;
    box-sizing: border-box;
    cursor: pointer;
}

.header-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-weight: bold;
    column-gap: 10px;
    margin-bottom: 15px;
}

.image-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin-top: 50px
}

.body {
    display: flex;
    flex-direction: column;
    flex: 1;
    font-weight: 500;
}

.footer {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: flex-end;
    text-align: center;
    margin-top: 20px;
}

.content-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    flex-grow: 1;
    align-items: center;
}

.number-image {
    height: 40px;
    width: 40px;
}

.number-tag {
    background-color: color;
    width: 20px;
    height: 20px;
    padding: 10px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 22px;
    user-select: none;
}

@media (min-width: $media-min-width) {
    .header-container {
        flex-direction: column;
        align-items: flex-start;
        row-gap: 15px;
    }

    .hiw-card-root {
        min-height: 275px;
        width: 100%;
        max-width: 250px;
    }
}
</style>