<template>
  <div style="padding: 35px;">
      {{ message }}
  </div>
</template>

<script>
export default {
    props: {
        message: {
            type: Error,
            requires: true
        }
    }
}
</script>

<style lang="scss" scoped>

</style>