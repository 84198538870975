<template>
  <div class="outer-container">
      <div class="grid-container">
          <div class="step-1-backdrop"></div>
          <div class="intermediate-backdrop-1"></div>
          <div class="step-2-backdrop"></div>
          <div class="intermediate-backdrop-2"></div>
          <div class="step-3-backdrop"></div>
          <div class="intermediate-backdrop-3"></div>
          <div class="step-4-backdrop"></div>

          <div class="step-1 section"><Step1Section /></div>
          <div class="step-2 section"><Step2Section /></div>
          <div class="step-3 section"><Step3Section /></div>
          <div class="step-4 section"><Step4Section /></div>
          <div class="additional-notes"><AdditionalNotesSection /></div>
      </div>
  </div>
</template>

<script>
import Step1Section from '@/components/Step1Section.vue'
import Step2Section from '@/components/Step2Section.vue'
import Step3Section from '@/components/Step3Section.vue'
import Step4Section from '@/components/Step4Section.vue'
import AdditionalNotesSection from '@/components/AdditionalNotesSection.vue'

export default {
    components: {
        Step1Section,
        Step2Section,
        Step3Section,
        Step4Section,
        AdditionalNotesSection
    }
}
</script>

<style lang="scss" scoped>
.grid-container {
  display: grid;
  width: 100%;
  min-height: 3000px;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(100, minmax(0px, max-content));
}

.step-1-backdrop {
    grid-area: 1 / 1 / 20 / 13;
    background: url("~@/assets/images/green-backdrop.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top left;
}

.intermediate-backdrop-1 {
    grid-area: 20 / 1 / 32 / 13;
    //background: url("~@/assets/images/grey-backdrop.png");
    background: #f3f3f3;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top left;
}

.step-2-backdrop {
    grid-area: 32 / 1 / 48 / 13;
    //background: url("~@/assets/images/blue-backdrop.png");
    background: #0096db;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top left;
}

.intermediate-backdrop-2 {
    grid-area: 48 / 1 / 52 / 13;
    //background: url("~@/assets/images/grey-backdrop.png");
    background: #f3f3f3;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top left;
}

.step-3-backdrop {
    grid-area: 52 / 1 / 72 / 13;
    //background: url("~@/assets/images/orange-backdrop.png");
    background: #F5B324;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top left;
}

.intermediate-backdrop-3 {
    grid-area: 72 / 1 / 76 / 13;
    background: #f3f3f3;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top left;
}

.step-4-backdrop {
    grid-area: 76 / 1 / 84 / 13;
    background: #d24a07;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top left;
}

.step-1 {
    grid-area: 1 / 2 / 32 / 12;
}

.step-2 {
    grid-area: 32 / 2 / 54 / 12;
}

.step-3 {
    grid-area: 54 / 1 / 76 / 13;
}

.step-4 {
    grid-area: 76 / 2 / 84 / 12;
}

.additional-notes {
    grid-area: 84 / 2 / 89 / 12;
}

.section {
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media (min-width: $media-min-width) {
    .grid-container {
        transform: translateY(-50px);
    }

    .step-1-backdrop {
        grid-area: 1 / 1 / 16 / 13;
        background-color: #f1f1f1;
    }

    .intermediate-backdrop-1 {
        grid-area: 16 / 1 /26 / 13;
    }

    .step-2-backdrop {
        grid-area: 26 / 1 / 44 / 13;
    }

    .intermediate-backdrop-2 {
        grid-area: 44 / 1 / 49 / 13;
    }

    .step-3-backdrop {
        grid-area: 49 / 1 / 68 / 13;
    }

    .intermediate-backdrop-3 {
        grid-area: 68 / 1 / 75 / 13;
    }

    .step-4-backdrop {
        grid-area: 71 / 1 / 85 / 13;
    }

    .step-1 {
        grid-area: 1 / 1 / 33 / 13;
    }

    .step-2 {
        grid-area: 33 / 2 / 54 / 12;
    }

    .step-3 {
        grid-area: 54 / 1 / 68 / 13;
    }

    .step-4 {
        grid-area: 68 / 2 / 85 / 12;
    }

    .additional-notes {
        grid-area: 90 / 2 / 100 / 12;
    }
}
</style>