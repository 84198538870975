<template>
<div :class="determineClass">
     <div :style="{background: color}"></div>
     <div :style="{background: color}"></div>
     <div :style="{background: color}"></div>
</div>
</template>

<script>
export default {
name: "Loader",
props: ['size', 'color'],
    computed: {
        determineClass() {
            let sizeUpper = this.size.toUpperCase();
            return sizeUpper == "SMALL" ? "lds-loader-small" :
                sizeUpper == "MEDIUM" ? "lds-loader-medium" :
                sizeUpper == "LARGE" ? "lds-loader-large" : "lds-loader-small";
        }
    }
}
</script>

<style lang="scss" scoped>
.grey {
    background: darkgrey;
}

.white {
    background: white;
}

.black {
    background: black;
}

.lds-loader-large {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}

.lds-loader-large div {
    display: inline-block;
    position: absolute;
    left: 8px;
    width: 16px;
    background: #b8b8b8;
    animation: lds-loader-large 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}

.lds-loader-large div:nth-child(1) {
    left: 8px;
    animation-delay: -0.24s;
}

.lds-loader-large div:nth-child(2) {
    left: 32px;
    animation-delay: -0.12s;
}

.lds-loader-large div:nth-child(3) {
    left: 56px;
    animation-delay: 0;
}

@keyframes lds-loader-large {
    0% {
        top: 8px;
        height: 64px;
    }

    50%, 100% {
        top: 24px;
        height: 32px;
    }
}

.lds-loader-medium {
    display: inline-block;
    position: relative;
    width: 40px;
    height: 40px;
}

.lds-loader-medium div {
    display: inline-block;
    position: absolute;
    left: 4px;
    width: 8px;
    background: #b8b8b8;
    animation: lds-loader-medium 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}

.lds-loader-medium div:nth-child(1) {
    left: 4px;
    animation-delay: -0.24s;
}

.lds-loader-medium div:nth-child(2) {
    left: 16px;
    animation-delay: -0.12s;
}

.lds-loader-medium div:nth-child(3) {
    left: 28px;
    animation-delay: 0;
}

@keyframes lds-loader-medium {
    0% {
        top: 4px;
        height: 32px;
    }

    50%, 100% {
        top: 12px;
        height: 16px;
    }
}

.lds-loader-small {
    display: inline-block;
    position: relative;
    width: 20px;
    height: 20px;
}

.lds-loader-small div {
    display: inline-block;
    position: absolute;
    left: 2px;
    width: 4px;
    background: #b8b8b8;
    animation: lds-loader-small 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}

.lds-loader-small div:nth-child(1) {
    left: 2px;
    animation-delay: -0.24s;
}

.lds-loader-small div:nth-child(2) {
    left: 8px;
    animation-delay: -0.12s;
}

.lds-loader-small div:nth-child(3) 
{
    left: 14px;
    animation-delay: 0;
}

@keyframes lds-loader-small 
{
    0% {
        top: 2px;
        height: 16px;
    }

    50%, 100% {
        top: 6px;
        height: 8px;
    }
}
</style>