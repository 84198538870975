<template>
<div class="welcome-section-input-container">
<div v-if="label">
    <span class="label">{{ label }}<span class="asterisk" v-if="required"> *</span></span>
</div>
<input type="text" :value="modelValue" @input="$emit('update:modelValue', $event.target.value)" :disabled="disabled">
</div>

</template>

<script>
export default {
    props: {
            modelValue: {
                type: String,
                default: '',
                required: false
            },
            label: {
                type: String,
                default: '',
                required: false
            },
            required: {
                type: Boolean,
                default: false,
                required: false
            },
            disabled: {
                type: Boolean,
                default: false,
                required: false
        }
    }
}
</script>

<style lang="scss" scoped>
.welcome-section-input-container {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 3px;
    margin-top: 13px;
}

.label {
    position: absolute;
    top: -10px;
    left: 5px;
    font-size: 12px;
    color: #B3B3B3;
    user-select: none;
    background: white;
}

.asterisk {
    font-size: 12px;
    color: red;
    user-select: none;
}

input {
    box-sizing: border-box;
    width: 100%;
    background-color: #fafafa;
    border: 2px solid #f3f3f3;
    border-radius: 5px;
    height: 40px;
    padding-left: 10px;
    border-top: none;
}
</style>