<template>
  <div class="welcome-section-form-container">
      <div class="imr-turnkey-label"><span>Please confirm your dealership information below:</span></div>

    <!-- <WelcomeSectionInput label="Your Dealership's Name" :modelValue="dealerName" @update:modelValue="$store.commit('UPDATE_DEALER_INFO', { prop: 'dealerName', value: $event })" required /> -->
    <WelcomeSectionInput label="Your Dealership's Name" v-model="dealerName" required :disabled="$store.state.opportunityOriginal.dealerInformation.dealerName ? true : false" />

    <div class="imr-information-group">
        <WelcomeSectionInput label="Dealer Group" v-model="dealerGroup" :disabled="$store.state.opportunityOriginal.dealerInformation.dealerGroup ? true : false" />
        <WelcomeSectionInput label="BAC" v-model="bac" required :disabled="$store.state.opportunityOriginal.dealerInformation.bacCode ? true : false" />
    </div>
      
    <div class="contact-information-group">
        <WelcomeSectionInput label="Street" v-model="street" required :disabled="$store.state.opportunityOriginal.address.street ? true : false" />

        <div class="city-state-sub-group">
            <WelcomeSectionInput label="City" v-model="city" required :disabled="$store.state.opportunityOriginal.address.city ? true : false" />
            <WelcomeSectionInput label="State" v-model="state" required :disabled="$store.state.opportunityOriginal.address.state ? true : false" />
        </div>
        
        <WelcomeSectionInput label="Zip" v-model="zip" required style="width: 49%;" :disabled="$store.state.opportunityOriginal.address.zip ? true : false" />
    </div>

    <WelcomeSectionInput label="Contact Name" v-model="contactName" disabled />
    <WelcomeSectionInput label="Contact Email" v-model="contactEmail" disabled />
    <div class="phone-number-group">
        <WelcomeSectionInput label="Contact Cell" v-model="userCell" />
        <WelcomeSectionInput label="Dealer Phone" v-model="dealerPhone" />
    </div>

    
    <WelcomeSectionInput label="Digital Air Strike Representative" v-model="dasRep" required disabled />
  </div>
</template>

<script>
import WelcomeSectionInput from './WelcomeSectionInput.vue'

export default {
  name: 'WelcomeSectionForm',
  components: {
    WelcomeSectionInput
  },
  computed: {
    dealerName: {
      get() { return this.$store.getters.dealerInformation.dealerName },
      set(value) { this.$store.commit("UPDATE_DEALER_INFO", { prop: "dealerName", value: value }) }
    },
    dealerGroup: {
      get() { return this.$store.getters.dealerInformation.dealerGroup },
      set(value) { this.$store.commit("UPDATE_DEALER_INFO", { prop: "dealerGroup", value: value }) }
    },
    bac: {
      get() { return this.$store.getters.dealerInformation.bacCode },
      set(value) { this.$store.commit("UPDATE_DEALER_INFO", { prop: "bacCode", value: value }) }
    },
    street: {
      get() { return this.$store.getters.address.street },
      set(value) { this.$store.commit("UPDATE_ADDRESS", { prop: "street", value: value }) }
    },
    city: {
      get() { return this.$store.getters.address.city },
      set(value) { this.$store.commit("UPDATE_ADDRESS", { prop: "city", value: value }) }
    },
    state: {
      get() { return this.$store.getters.address.state },
      set(value) { this.$store.commit("UPDATE_ADDRESS", { prop: "state", value: value }) }
    },
    zip: {
      get() { return this.$store.getters.address.zip },
      set(value) { this.$store.commit("UPDATE_ADDRESS", { prop: "zip", value: value }) }
    },
    dealerPhone: {
      get() { return this.$store.getters.dealerInformation.dealerPhone  },
      set(value) { this.$store.commit("UPDATE_DEALER_INFO", { prop: "dealerPhone", value: value }) }
    },
    userCell: {
      get() { return this.$store.getters.opportunity.userPhone },
      set(value) { this.$store.commit("UPDATE_OPPORTUNITY", { prop: "userPhone", value: value }) }
    },
    dasRep: {
      get() { return this.$store.getters.opportunity.digitalAirstrikeRepresentative },
      // set(value) { this.$store.commit("UPDATE_OPPORTUNITY", { prop: "digitalAirstrikeRepresentative", value: value }) }
    },
    contactName: {
      get() { return this.$store.getters.dealerInformation.contactName },
      set(value) { this.$store.commit("UPDATE_DEALER_INFO", { prop: "contactName", value: value }) }
    },
    contactEmail: {
      get() { return this.$store.getters.dealerInformation.contactEmail },
      set(value) { this.$store.commit("UPDATE_DEALER_INFO", { prop: "contactEmail", value: value }) }
    }
  },
}
</script>

<style lang="scss" scoped>
.welcome-section-form-container {
  display: flex;
  flex-direction: column;
  height: max-content;
  width: 100%;
  box-sizing: border-box;
  background-color: #ffffff;
  border-radius: 16px;
  padding: 30px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  gap: 5px;
}

.imr-turnkey-label {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    width: 100%;
    color: #4D4D4D;
    font-size: clamp(16px, 3.5vw, 22px);
    font-weight: bold;
    letter-spacing: 0;
    line-height: 32px;
    user-select: none;
}

.city-state-sub-group {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.phone-number-group {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

@media (min-width: $media-min-width) {
  .imr-turnkey-label {
    font-size: clamp(14px, 2.5vw, 22px);
  }
}
</style>