import { createApp } from 'vue'
import App from './App.vue'
import store from './store'
// import { clarity } from 'clarity-js';
import { polyfill } from "seamless-scroll-polyfill";


polyfill();
document.title = 'SMRM';

const useDev = (window.location.host.includes("localhost") || window.location.host.includes("ambitious-tree-0e8fa8110"))

// clarity.start({
//     projectId: "k2uhktliz5",
//     upload: 'https://m.clarity.ms/collect',
//     track: true,
//     content: true,
// });

const app = createApp(App).use(store);
//app.config.globalProperties.$Clarity = clarity;
app.mount('#app');
