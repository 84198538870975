<template>
  <div class="outer-container" id="start-anchor">
      <div class="inner-container">
          <div class="grid-container">
            <!-- <div class="backdrop"></div> -->

            <div class="title">
                <h2>How it works</h2>
            </div>

            <div class="cards">
                <HowItWorksSectionCard number="1" color="#66bc29" :content="$store.getters.stringObject('step_1_card')" @click.prevent="scrollTo('step-1-scroll-anchor')" />
                <HowItWorksSectionCard number="2" color="#0096db" :content="$store.getters.stringObject('step_2_card')" @click.prevent="scrollTo('step-2-scroll-anchor')" :footerStyle="{ width: '90%' }" :imageSrc="googleLogo" :imageDimensions="{ width: '75px', paddingBottom: '10px' }"/>
                <HowItWorksSectionCard number="3" color="#F5B324" :content="$store.getters.stringObject('step_3_card')" @click.prevent="scrollTo('step-3-scroll-anchor')" :imageSrc="ai" :imageDimensions="{ width: '100px' }"/>
                <HowItWorksSectionCard number="4" color="#d97c1f" :content="$store.getters.stringObject('step_4_card')" @click.prevent="scrollTo('step-4-scroll-anchor')" />
                
            </div>
          </div>
      </div>
  </div>
</template>

<script>
import HowItWorksSectionCard from './HowItWorksSectionCard.vue'
import googleLogo from '@/assets/images/google-logo.svg';
import ai from '@/assets/images/ai.png';

export default {
    components: {
        HowItWorksSectionCard
    },
    data() {
    return {
      googleLogo,
      ai
    };
  },
    methods: {
        scrollTo(elementId) {
            var element = document.getElementById(elementId);
            element.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
        }
    }
}
</script>

<style lang="scss" scoped>
.outer-container {
    background: url("~@/assets/images/how-it-works-backdrop.png");
    background-repeat: no-repeat;
    background-size: cover;
}

.grid-container {
  display: grid;
  width: 100%;
  height: 100%;
  margin-top: 10px;
  margin-bottom: 50px;
  justify-content: center;
  grid-template-columns: repeat(3, minmax(0px, max-content));
  grid-template-rows: repeat(3, minmax(0px, max-content));
}

.title { 
    display: grid;
    grid-area: 1 / 1 / 2 / 4;
    color: #4D4D4D;
}

.cards { 
    display: grid;
    grid-area: 2 / 1 / 4 / 4;
    gap: 20px;
    grid-auto-flow: row;
}

.column-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 20px;
}

#hiw-section-label {
    display: flex;
    justify-content: center;
    color: #4D4D4D;
    font-size: clamp(26px, 2.5vw, 32px);
}

@media (min-width: 650px) {
    .cards {
        grid-auto-flow: column;
    }

    .grid-container {
        margin-top: 50px;
        margin-bottom: 100px;
    }
}
</style>