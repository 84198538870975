<template>
  <div class="step-2-section-root" id="step-2-scroll-anchor">
    
      <div class="header">
          <div class="header-sub-left">
                <div style="background-color: rgba(255,255,255,0.2); border-radius: 5px; max-width: 100px; padding: 5px;"><img src="@/assets/images/google-logo.png" alt="Google logo" style="max-width: 100px;"></div>
                <div class="label">Step Two:</div>
                <div class="sub-label">Don't forget about your Google Business Profile!</div>
                <div class="flavor-text">As a GM dealer you get exclusive discounts on DAS Google Business Profile packages which are designed to help you supercharge your Google Business Profile!  DAS will help you manage your Google virtual showroom and help you improve your local SEO, how often you come up in searches, and drive website traffic!</div>
                <div class="disclaimer-text">* View full package details <a href="https://dasgm2024.com/new-google-business-profile/" target="_blank" style="text-decoration: none;">here</a>. Discounts applicable during 2024 SMRM Enrollment period.</div>
              </div>
            <div class="header-sub-right">2</div>
      </div>

      <span class="card-container-label">Pick One</span>

      <div class="cards">
        <PackageSectionCard :cardPackage="packages.googleBusinessProfileDominate" @click="packageSelected(packages.googleBusinessProfileDominate)"/>
        <PackageSectionCard :cardPackage="packages.googleBusinessProfileDefend" @click="packageSelected(packages.googleBusinessProfileDefend)"/>
      </div>

        <p>
            <input type="checkbox" id="google-decline" v-model="decline" />
            <label for="google-decline" style="color: white; font-size: 12px;">I decline the Google Business Profile and understand by opting out that I am forfeiting on behalf of the dealership up to $6,000 in annual savings to help us sell more in 2024.</label>
        </p>
  </div>
</template>

<script>
import PackageSectionCard from '@/components/PackageSectionCard.vue'

export default {
    components: {
        PackageSectionCard
    },
    computed: {
        packages() {
            return this.$store.state.packages;
        },
        decline: {
            get() {
                return this.$store.state.googleOptOut;
            },
            set(value) {
                if(value == true) {
                    this.$store.commit('GOOGLE_OPT_OUT');
                }
            }
      }
    },
    methods: {
        packageSelected(pkg) {
            this.$store.commit('TOGGLE_GOOGLE_PACKAGE', pkg);
        },
    }
}
</script>

<style lang="scss" scoped>
#decline {
    margin-top: 15px;
}

.step-2-section-root {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    align-items: center;
}

.header {
    display: flex;
    margin-top: 20px;
}

.header-sub-left {
    display: flex;
    flex-direction: column;
    width: 70%;
    row-gap: 10px;
}

.header-sub-right {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 30%;
    color: white;
    text-align: right;
    font-size: 150px;
    opacity: 0.5;
    font-weight: bold;
}

.label {
    font-size: 26px;
    font-weight: bold;
    color: white;
}

.sub-label {
    color: #FFF;
    font-weight: bold;
}

.flavor-text {
    color: white;
    font-size: 12px;
}

.disclaimer-text {
    color: white;
    font-size: 10px;
}

.card-container-label {
        color: white;
        font-size: 1rem;
        margin-top: 25px;
        font-weight: bold;
        user-select: none;
    }

.cards {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: max-content;
    row-gap: 20px;
    margin-top: 25px;
}

[type="checkbox"]:not(:checked),
  [type="checkbox"]:checked {
    position: absolute;
    left: 0;
    opacity: 0.01;
  }
  [type="checkbox"]:not(:checked) + label,
  [type="checkbox"]:checked + label {
    position: relative;
    padding-left: 2.3em;
    font-size: 1.05em;
    line-height: 1.7;
    cursor: pointer;
  }

  /* checkbox aspect */
  [type="checkbox"]:not(:checked) + label:before,
  [type="checkbox"]:checked + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 1.4em;
    height: 1.4em;
    border: 1px solid rgb(255, 255, 255);
    background: rgba(0,0,0,0.0);
    border-radius: .2em;
    box-shadow: inset 0 1px 3px rgba(0,0,0, .1), 0 0 0 rgba(203, 34, 237, .2);
    -webkit-transition: all .275s;
        transition: all .275s;
  }

  /* checked mark aspect */
  [type="checkbox"]:not(:checked) + label:after,
  [type="checkbox"]:checked + label:after {
    content: '✕';
    position: absolute;
    top: .525em;
    left: .18em;
    font-size: 1.375em;
    color: #FFF;
    line-height: 0;
    -webkit-transition: all .2s;
        transition: all .2s;
  }

  /* checked mark aspect changes */
  [type="checkbox"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0) rotate(45deg);
        transform: scale(0) rotate(45deg);
  }

  [type="checkbox"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1) rotate(0);
        transform: scale(1) rotate(0);
  }

  /* Disabled checkbox */
  [type="checkbox"]:disabled:not(:checked) + label:before,
  [type="checkbox"]:disabled:checked + label:before {
    box-shadow: none;
    border-color: #bbb;
    background-color: #e9e9e9;
  }

  [type="checkbox"]:disabled:checked + label:after {
    color: #777;
  }

  [type="checkbox"]:disabled + label {
    color: #aaa;
  }

  /* Accessibility */
  [type="checkbox"]:checked:focus + label:before,
  [type="checkbox"]:not(:checked):focus + label:before {
    box-shadow: inset 0 1px 3px rgba(0,0,0, .1), 0 0 0 6px rgba(78, 78, 78, 0.2);
  }

@media (min-width: $media-min-width) {
    .step-2-section-root {
        margin-top: 80px;
    }

    .header {
        margin-top: 0px;
    } 

    .label {
        font-size: 42px
    }

    .sub-label {
        font-size: 18px;
    }

    .flavor-text { 
        font-size: 11px;
    }

    .disclaimer-text {
        font-size: 11px;
    }

    .header-sub-right {
        font-size: 200px;
    }
    
    

    .cards {
        flex-direction: row;
        column-gap: 20px;
        align-items: center;
        justify-content: space-around;
        box-sizing: border-box;
        margin-top: 15px;
        padding-bottom: 20px;
    }
}
</style>