<template>
  <div class="viewport">
    <ErrorPage v-if="$store.state.error" />
    <LoadingPage v-else-if="$store.getters.appLoading" />
    <ThankYouPage v-else-if="$store.state.opportunity.contractSigned" />
    <LandingPage v-else />

    <Modal v-show="$store.state.modal.show" @close="$store.dispatch('hideModal')" :title="$store.state.modal.title" >
      <ModalErrorMessageSubview v-if="$store.state.modal.subview == 'error'" :message="$store.state.modalErrorMessageSubview.message" />
      <ModalIncompleteItemsSubview v-else-if="$store.state.modal.subview == 'incompleteItems'" :messages="$store.state.modalIncompleteItemsSubview.messages" />
      <ModalSignatureSubview v-else-if="$store.state.modal.subview == 'signature'" @cancelled="$store.dispatch('hideModal')" @accepted="updateSignature" />
    </Modal>

    <LoadingSplash scope="fixed" v-if="$store.state.showLoadingSplash" />
  </div>
</template>

<script>
import LoadingPage from '@/views/LoadingPage.vue'
import ErrorPage from '@/views/ErrorPage.vue'
import LandingPage from '@/views/LandingPage.vue'
import ThankYouPage from '@/views/ThankYouPage.vue'
import Modal from '@/components/reusable/Modal.vue'
import LoadingSplash from '@/components/reusable/LoadingSplash.vue'

import ModalErrorMessageSubview from "@/components/reusable/ModalErrorMessageSubview.vue"
import ModalIncompleteItemsSubview from "@/components/reusable/ModalIncompleteItemsSubview.vue"
import ModalSignatureSubview from "@/components/reusable/ModalSignatureSubview.vue"


export default {
  components:{
    LandingPage,
    LoadingPage,
    ErrorPage,
    ThankYouPage,
    Modal,
    LoadingSplash,
    ModalErrorMessageSubview,
    ModalIncompleteItemsSubview,
    ModalSignatureSubview
  },
  computed: {

  },
  methods: {
    updateSignature(data) {
      //this.$store.dispatch("sendFullStoryEvent", { FS: this.$FullStory, eventName: "SFE-DocumentSigned" });
      this.$store.dispatch('updateSignature', data);
      this.$store.dispatch('hideModal');
    }
  },
  created() {
    this.$store.dispatch("loadInitialState");

    // When we finish loading the initial app state, identify FS
    this.unwatch = this.$store.watch(
      (state, getters) => getters.appLoading,
      (newValue, oldValue, state) => {
        if (newValue === false) {

          const clientId = this.$store.state.opportunity.clientId;
          const displayName = this.$store.state.opportunity.dealerInformation.dealerName;  
          const dealership = this.$store.state.opportunity.dealerInformation.dealerName;
          const dealershipGroup = this.$store.state.opportunity.dealerInformation.dealerGroup;

          //window.clarity("identify", "monakane@contoso.com", "custom-session-id", "home-page-id", "Mona");
          window.clarity("identify", clientId, null, dealership, displayName);
        }
      },
    );
  },
  beforeUnmount() {
    this.unwatch();
  },
}
</script>


<style lang="scss">
body {
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu;
}

button {
  cursor: pointer;
}

img {
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
}

.viewport {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  max-width: 100%;
  background-color: #F5F5F6;
}

.outer-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1024px;
  background: white;
}

.inner-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
}

.animated {
  position: relative;
}

.animated::after {
  content: "";
  position: absolute;
  --angle: 0deg;
  border: none;
  background: conic-gradient(from var(--angle), #f79533, #f37055, #ef4e7b, #a166ab, #5073b8, #1098ad, #07b39b, #6fba82, #f79533);
  border-radius: 15px;
  animation: 1s rotate linear infinite !important;
  padding: 10px;
  box-sizing: content-box;
}

@keyframes rotate {
  to {
    --angle: 360deg;
  }
}

@property --angle {
  syntax: '<angle>';
  initial-value: 0deg;
  inherits: false;
}


// .animated {
//     background-image:
//     linear-gradient(white, white),
//     linear-gradient(60deg, #f79533, #f37055, #ef4e7b, #a166ab, #5073b8, #1098ad, #07b39b, #6fba82);
//     border-radius: 15px;;
//     background-repeat: no-repeat;
//     background-size: 100% 100%, 100% 200%;
//     background-position: 0 0, 0 0;
//     background-origin: padding-box, border-box;
//     animation: highlight 0.5s infinite alternate;
// }

// @keyframes highlight {
//     0% {
//     background-position: 0 0, 0 0%;
//     }
//     50% {
//     background-position: 0 0, 0 50%;
//     }
//     100% {
//     background-position: 0 0, 0 100%;
//     }
// }

@media (max-width: 600px) {
  
}
</style>
