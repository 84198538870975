<template>
    <div class="gift-banner-container">
        <div class="gift-banner-text-container">
            <span class="gift-banner-text-header"><b>ENROLL TODAY</b></span>
            <span class="gift-banner-text-text">Get tech gifts with any solution! </span>
        </div>

        <div style="display: flex; flex-direction: row; justify-content: center; align-items: flex-start; column-gap: 15px;">
            <div class="gift-banner-image-container"><img src="@/assets/images/gifts/google-pixel-watch.png" alt="Google Pixel Watch" />Google Pixel<br>Watch</div>
            <div class="gift-banner-image-container"><img id="yeti-rambler-image" src="@/assets/images/gifts/yeti-tumbler.png" alt="Yeti Rambler" />Yeti<br>Rambler</div>
            <div class="gift-banner-image-container"><img id="gift-3" src="@/assets/images/gifts/apple-watch-series-6.jpg" alt="Apple Watch Series 6" />Apple Watch<br>Series 6</div>
            <div class="gift-banner-image-container"><img id="apple-gift-card" src="@/assets/images/gifts/apple-gift-card.png" alt="Apple Gift Card" />Apple<br>Gift Card</div>
        </div>
    </div>
  <div class="step-1-section-root" id="step-1-scroll-anchor">
    
      <div class="header">
          <div class="header-sub-left">
                  <div class="label">Step One:</div>
                  <div class="sub-label">Lead the market with Digital Air Strike's exclusive Social Media and Reputation Management solutions for GM Dealers. It's time to go with the best… and get one or more of these incredible technology fits with every solution!<br><br>Buy 1 of these and get a Google Pixel watch plus a Yeti.<br><br>Add a second solution and get Apple Watch plus a Yeti, and ask us how you can also get a $100 Apple Store gift card!</div>
                  <div style="color: white; font-size: 11px;">Choose the packages that work best for your dealership.</div>
              </div>
            <div class="header-sub-right">1</div>
      </div>

        <div class="cards">
              <PackageSectionCard :cardPackage="packages.socialDominate" @click="packageSelected(packages.socialDominate)"/>
                <div class="reputation-card-section">
                    <span>Pick One</span>
                    <div class="reputation-cards">
                        <PackageSectionCard :cardPackage="packages.reputationDefend" @click="packageSelected(packages.reputationDefend)"/>
                        <PackageSectionCard :cardPackage="packages.reputationEngage" @click="packageSelected(packages.reputationEngage)"/>
                    </div>
                </div>
          </div>
  </div>
</template>

<script>
import PackageSectionCard from '@/components/PackageSectionCard.vue'
import GiftCard from '@/components/reusable/GiftCard.vue'

export default {
    components: {
        PackageSectionCard,
        GiftCard
    },
    computed: {
        packages() {
            return this.$store.state.packages;
        }
    },
    methods: {
        packageSelected(pkg) {
            // this.$store.dispatch("sendFullStoryEvent", { 
            //     FS: this.$FullStory,
            //     eventName: "SMRM-PackageSelected",
            //     eventPayload: {
            //         package: pkg.name
            //     }
            // });

            this.$store.commit('TOGGLE_GM_PACKAGE', pkg)
        },
    }
}
</script>

<style lang="scss" scoped>
.step-1-section-root {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 90%;
    padding-bottom: 35px;
}

.gift-banner-text-container {
    color: white;
    text-align: center;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}

.gift-banner-text-header {
    font-size: 42px;
}

.gift-banner-text-text {
    font-size: 12px;
}

.gift-banner-container {
    right: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    column-gap: 15px;
}

.gift-banner-image-container {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    text-align: center;
    font-size: 10px;
}

.gift-banner-image-container > img {
    max-width: 60px;
    max-height: 60px;
    border-radius: 100%;
    border: 3px orange solid;
    padding: 5px;
    background-color: white;
}

#yeti-rambler-image {
    padding-left: 20px;
    padding-right: 20px;
}

#apple-gift-card {
    padding-top: 15px;
    padding-bottom: 15px;
}

#gift-3 {
    padding-right: 11px;
    padding-left: 11px;
}

.header {
    display: flex;
}

.header-sub-left {
    display: flex;
    flex-direction: column;
    width: 70%;
    row-gap: 10px;
}

.header-sub-right {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 30%;
    color: white;
    text-align: right;
    font-size: 150px;
    opacity: 0.5;
    font-weight: bold;
}

.label {
    font-size: 26px;
    font-weight: bold;
    color: white;
}

.sub-label {
    color: #FFF;
    font-weight: bold;
}

// .content {
//     display: flex;
//     flex-direction: column;
//     width: 100%;
//     height: 100%;
// }

.cards {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: max-content;
    row-gap: 25px;
    margin-top: 25px;
}

.reputation-card-section {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.reputation-card-section > span {
    color: white;
    font-weight: 1rem;
    font-weight: bold;
    margin-bottom: 15px;
}

.reputation-cards {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: max-content;
    row-gap: 25px;
}

// .cards > div {
//     flex: 1;
// }

@media (min-width: $media-min-width) {
    .step-1-section-root {
        padding-bottom: 0px;
    }

    .gift-banner-text-container {
        color: white;
        text-align: left;
        display: flex;
        flex-direction: column;
        padding-left: 100px;
        width: 30%;
    }

    .gift-banner-text-header {
        font-size: 36px;
    }

    .gift-banner-text-text {
        font-size: 16px;
        font-style: italic;
    }

    .gift-banner-container {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-right: 50px;
    }

    .gift-banner-image-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: white;
        text-align: center;
    }

    .gift-banner-image-container > img {
        max-width: 100px;
        max-height: 100px;
        border-radius: 100%;
        border: 3px orange solid;
        padding: 5px;
        background-color: white;
    }

    #yeti-rambler-image {
    padding-left: 29px;
    padding-right: 29px;
}

#apple-gift-card {
    padding-top: 20px;
    padding-bottom: 20px;
}

#gift-3 {
    padding-right: 13px;
    padding-left: 13px;
}

    .cards {
        flex-direction: row;
        column-gap: 20px;
        align-items: center;
        justify-content: space-around;
        box-sizing: border-box;
        // margin-top: 250px;
        // transform: translateY(-230px);
    }

    .reputation-card-section {
        min-width: 65%;
    }

    .reputation-cards {
        flex-direction: row;
        column-gap: 20px;
        align-items: center;
        justify-content: space-around;
        box-sizing: border-box;
        margin-top: 0px;
    }

    // .cards > div:nth-child(3) {
    //     order: 1;
    // }

    // .cards > div:nth-child(1) {
    //     order: 2;
    // }

    // .cards > div:nth-child(2) {
    //     order: 3;
    // }    
    

    .label {
        font-size: 42px
    }

    .header-sub-right {
        font-size: 200px;
    }
}
</style>