<template>
  <div class="modal-backdrop" :style="{'position': scope}">
      <div class="container">
        <Loader size="large" color="white"/>
      </div>
  </div>
</template>

<script>
import Loader from './Loader.vue'

export default {
    name: "LoadingSplash",
    props: ['scope'],
    components: {
        Loader
    }
}
</script>

<style scoped>
.modal-backdrop {
    position: relative;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    background-color: rgba(0,0,0,0.2);
    user-select: none;
    touch-action: none;
}

.container {
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
}
</style>