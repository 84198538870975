<template>
  <div style="">
        <div style="font-size: 12px; text-align: center;">Please fix the following issues before trying again:</div> 
        <ul>
          <li v-for="message in messages" :key="message" style="font-weight: 500;">{{ message }}</li>
        </ul>
    </div>
</template>

<script>
export default {
    props: {
        messages: {
            type: Array,
            required: true
        }
    }
}
</script>

<style lang="scss" scoped>

</style>