<template>
  <div class="error-page-root">
    <!-- <div>
      <h1>Darn</h1>
      <h3>Something went wrong</h3>
      <div><b>Error code:</b> {{ $store.state.error.code }}</div>
      <div><b>Error message:</b> {{ $store.state.error.message }}</div>
    </div> -->
    <div class="logo-container">
      <img class="das-logo" src="@/assets/images/das-logo-black.png" alt="das">
    </div>
    
    <div class="container">
      <div class="message">
        
        <div class="error-content">
          <div class="error-code">{{ $store.state.error.code }}</div>
          <div class="error-message">{{ $store.state.error.message }}</div>
          <div class="contact-info">
            <h4><a href="mailto: support@digitalairstrike.com" style="text-decoration: none; link">support@digitalairstrike.com</a></h4>
            <h4>1-888-403-2192</h4>
          </div>
        </div>
      </div>

      <div class="image"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ErrorPage',
  components: {
    
  }
}
</script>

<style lang="scss" scoped>
.error-page-root {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background: white;
  color: #424142;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  margin-top: 50px;
}

img {
  object-fit: cover;
}

h4 {
  text-align: center;
}

.message {
  display: flex;
  flex-direction: column;
}

.error-code {
  font-family: 'Zen Kurenaido', sans-serif;
  font-size: clamp(56px, 15vw, 200px);
  font-weight: 600;
}

.error-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.error-message {
  text-align: center;
}

.logo-container {
  margin-top: 50px;
}

.image {
  display: flex;
  width: 100%;
  flex-grow: 1;
  background: url("~@/assets/images/das-wiz-w-bg.png");
  background-size: contain;
  background-repeat:no-repeat;
  background-position: top;
  max-width: 80%;
  margin-top: 25px;
}

@media (min-width: $media-min-width) {
  .error-page-root {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .container {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-template-rows: 1fr;
    column-gap: 50px;
    grid-auto-flow: row;
    height: 100%;
    width: 80%;
    max-width: 1024px;
    flex-direction: unset;
    align-items: unset;
    margin-top: unset;
  }

  .message { 
    grid-area: 1 / 1 / 2 / 2;
  }

  .logo-container {
    width: 80%;
    max-width: 1024px;
  }

  .image { 
    grid-area: 1 / 2 / 2 / 3;
    background-position: center;
    margin-top: unset;
  }

  .error-content {
    display: flex;
    flex-direction: column;
    align-items: unset;
    justify-content: center;
    row-gap: 5px;
    margin-bottom: 75%;
    flex-grow: 1;
  }

  h4 {
    text-align: left;
  }
}
</style>