<template>
<div class="gift-card-root">
    <img class="gift-image" :src="imageSrc" alt="Gift">
    <div class="title">{{ title }}</div>
    <img v-if="secondaryImageSrc" class="gift-secondary-image" :src="secondaryImageSrc" />
</div>
</template>

<script>
export default {
    components: {
        
    },
    props: {
        imageSrc: {
            type: String,
            required: true
        },
        secondaryImageSrc: {
            type: String,
            required: false
        },
        borderColor: {
            type: String,
            required: false,
            default: '#ed7d31'
        },
        title: {
            type: String,
            required: true
        }
    },
    data() {

    },
    computed: {

    },
    methods: {

    }
}
</script>

<style lang="scss" scoped>
.gift-card-root {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.gift-image {
    border-radius: 100%;
    border: 1px orange solid;
    max-width: 75px;
}

.gift-secondary-image {
    max-width: 50px;
}

</style>