<template>
  <div class="step-3-section-root" id="step-3-scroll-anchor">
      <div class="header">
          <div class="header-sub-left">
                  <div class="label">Step Three:</div>
                  <div class="sub-label"><b><i>GET EXCLUSIVE GIFTS JUST FOR GM DEALERS!</i></b><br><br>You can get additional tech gifts when you enhance your solutions with Digital Air Strike with our exclusive Power Packs!<br><br>When you add one of these Power Packs to your program  you’ll receive the newest Series 9 Apple watch and a MagSafe wireless phone charger!</div>
                  <div class="flavor-text">Digital Air Strike's patented Lead Response Technology enables you to consistently follow up with all leads that come in after hours, so you never miss a sale.</div>
                  <div class="disclaimer-text">* View full package details <a href="https://dasgm2024.com/lead-response-pre-order-engagement-solutions/" target="_blank" style="text-decoration: none;">here</a>. Discounts applicable during 2024 SMRM Enrollment period.</div>
              </div>
            <div class="header-sub-right">3</div>
      </div>

        <div class="image-banner-container">
            <div class="image-banner-item"><img src="@/assets/images/gifts/apple-watch-series-9.png" alt="Apple Watch Series 9 Gift" />Apple Watch<br>Series 9</div>
            <div class="image-banner-item"><img src="@/assets/images/gifts/maglight.png" alt="Apple MagSafe Charger Gift" />Apple MagSafe<br>Charger</div>
        </div>

      <div class="cards">
        <AddonCard :addon="$store.state.addons.good" />
        <AddonCard :addon="$store.state.addons.better" />
        <AddonCard :addon="$store.state.addons.best" />
      </div>

      <div class="banner-label">
        <span style="font-weight: bold; font-size: 2rem;">ULTIMATE PACKAGE</span>
        <span>When you purchase the top level of all Digital Air Strike packages, you will receive the special edition Apple Watch X Hermes air in your choice of 41 or 45 MM.</span>
      </div>
      
      <img style="width: 100%;" src="@/assets/images/step-3-banner.png" alt="Gift Banner" />
      <img style="width: 100%;" src="@/assets/images/step-3-banner-2.png" alt="Big Game Giveaway Banner" />
 
  </div>
</template>

<script>
import AddonCard from '@/components/AddonCard.vue'

export default {
  components: {
    AddonCard
  }
}
</script>

<style lang="scss" scoped>
.step-3-section-root {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    align-items: center;
}

.banner-label {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    color: #4D4D4D;
    padding-bottom: 15px;
    width: 85%;
  }

.header {
    display: flex;
    margin-top: 20px;
    width: 85%;
}
.image-banner-container {
    display: flex;
    flex-direction: row;
    column-gap: 15px;
    justify-content: center;
    margin-top: 25px;
}

.image-banner-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    text-align: center;
}

.image-banner-item > img {
    height: 100%;
    width: 150px;
}

.header-sub-left {
    display: flex;
    flex-direction: column;
    width: 70%;
    row-gap: 10px;
}

.header-sub-right {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 30%;
    color: white;
    text-align: right;
    font-size: 150px;
    opacity: 0.5;
    font-weight: bold;
}

.label {
    font-size: 26px;
    font-weight: bold;
    color: white;
}

.sub-label {
    color: #FFF;
    font-weight: bold;
}

.flavor-text {
    color: white;
    font-size: 12px;
}

.disclaimer-text {
    color: white;
    font-size: 10px;
}

.image-banner {
    display: flex;
    flex-direction: row;
    background-color: #f3f3f3;
    border-radius: 5px;
    height: 100px;
    margin-top: 25px;
    width: 90%;
}

.cards {
    display: flex;
    flex-direction: column;
    width: 70%;
    height: 100%;
    align-items: center;
    row-gap: 30px;
    margin-top: 25px;
    padding-bottom: 50px;
}


@media (min-width: $media-min-width) {
    // .step-3-section-root {

    // }

    .banner-label {
        color: white;
        width: 80%;
    }

    .header {
        margin-top: 0px;
    } 

    .label {
        font-size: 42px
    }

    .sub-label {
        font-size: 18px;
    }

    .flavor-text { 
        font-size: 11px;
    }

    .disclaimer-text {
        font-size: 11px;
    }

    .header-sub-right {
        font-size: 200px;
    }

    .image-banner {
        margin-top: 0px;
        margin-bottom: 15px;
    }

    .cards {
        margin-top: 15px;
        width: 90%;
    }
}
</style>